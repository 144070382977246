import { tmLoadModal } from '@uc-tm/modal-loader';
import get from 'lodash/get';
import partial from 'lodash/partial';
import pick from 'lodash/pick';
import type TransactionTemplate from 'src/models/transaction-templates/transaction-template';
import type Transaction from 'src/models/transactions/transaction';
import appStore from 'src/stores/app-store';
import { getOfferCover } from '../transactions/properties/get-offer-cover';

type LoadTemplateMetaModalOptions = {
  clone?: Transaction;
  template?: TransactionTemplate;
  navigateOnOk?: boolean;
  showTitleField?: boolean;
  showShare?: boolean;
  showShareWithEveryone?: boolean;
  templateDetailRouteName?: string;
};

/**
 * Load TemplateMetaModal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--template-meta
 */
export const loadTemplateMetaModal = async (
  options: LoadTemplateMetaModalOptions
) => {
  const { account, router, ui, transactionTemplates } = appStore;
  const {
    clone,
    template,
    navigateOnOk = true,
    showTitleField = true,
    showShare,
    showShareWithEveryone = true,
    templateDetailRouteName,
  } = options;

  const modalTitle: string = (() => {
    if (template) return template.title;
    if (clone) {
      if (clone.isTemplate) return `Clone template: ${clone.title}`;
      return `Save as Template`;
    }
    return 'New Template';
  })();

  const cloneFromTransaction = (() => {
    if (template || !clone) return false;
    return !clone.isTemplate;
  })();

  const okText = template || cloneFromTransaction ? 'Save' : 'Create Template';

  const showMovePropertyDocsText = (() => {
    if (!cloneFromTransaction || !clone?.hasAddress) {
      return false;
    }
    return clone?.isPurchase;
  })();

  const transactionProperties =
    clone?.getProperties()?.filter((pi: any) => pi.address) ?? [];

  const showPropertySelection = (() => {
    // Show this selection only when save template from transaction without
    // primary property and there are more than one property\
    if (!cloneFromTransaction || clone?.hasAddress) return false;
    return transactionProperties.length > 1;
  })();

  const isCreateNewTemplate = !template && !clone;

  const canChangeVisibility = (() => {
    if (!account.isBrokerageAccount) {
      return false;
    }
    if (account.isBrokerageAdmin) {
      return true;
    }
    return !template || template.canManage;
  })();

  const initialTitle = (() => {
    if (template) return template.title;
    if (clone) {
      if (clone.isTemplate) return `Copy of ${clone.title}`;
      return `Template from ${clone.title}`;
    }
    return 'New Template';
  })();

  const initialType = (() => {
    if (template || clone) {
      const side = get(template || clone, 'side');
      const isLease = get(template || clone, 'isLease', false);
      return isLease ? `${side}_LEASE` : side;
    }
    return undefined;
  })();

  const allTeams =
    transactionTemplates.getTeams() ||
    (await transactionTemplates.getFetchTeams.fetch());

  const allTeamIds = allTeams?.map((t) => t.id) ?? [];

  const initialVisibility =
    account.isBrokerageAccount && template
      ? template?.meta?.templateVisibility
      : 'PERSONAL';

  const initialTeamIds = (() => {
    if (account?.isBrokerageAccount && template) {
      return (
        template?.teamIds?.filter((id: string) => allTeamIds.includes(id)) ?? []
      );
    }
    return [];
  })();

  const initialSavedPropertyInfoId = (() => {
    // For addressless transaction with promoted property
    // Or addressless transaction with only one valid property
    // Use this property for saving template by default
    if (!cloneFromTransaction) {
      return null;
    }
    if (clone?.hasAddress) {
      return clone.propertyInfoId;
    }
    if (transactionProperties.length !== 1) {
      return null;
    }
    return transactionProperties[0]?.id;
  })();

  const initialValues = {
    title: initialTitle,
    type: initialType,
    visibility: initialVisibility,
    teamIds: initialTeamIds,
    savedPropertyInfoId: initialSavedPropertyInfoId,
  };

  const selectableTeamIds = (() => {
    const teamsCanManage = transactionTemplates.teamsCanManage();
    const { offices = [], groups = [] } = teamsCanManage;
    return offices.concat(groups).map((t) => t.id);
  })();

  const visibleTeams = transactionTemplates.teamsCanView();

  try {
    await tmLoadModal(
      'tm/template-meta/0',
      // `https://dev.local.compass.com:5443/ucfe-assets/tm/modal--template-meta/latest/modal.esm.js?${Date.now()}`,
      {
        title: modalTitle,
        okText,
        showTitleField,
        showMovePropertyDocsText,
        propertyFieldOptions: {
          show: showPropertySelection,
          radioCardOptions: transactionProperties.map((propertyInfo: any) => ({
            value: propertyInfo.id,
            label: propertyInfo.address.street,
            subLabel: getOfferCover(propertyInfo.offerPackage, propertyInfo)
              ?.label,
            disabled: !!propertyInfo.offerPackage,
          })),
        },
        typeFieldOptions: {
          show: isCreateNewTemplate,
          disabled: Boolean(template || clone),
          radioOptions: [
            { value: 'SALE', label: 'Listing' },
            { value: 'PURCHASE', label: 'Purchase' },
            { value: 'SALE_LEASE', label: 'Lease Listing' },
            { value: 'PURCHASE_LEASE', label: 'Lease' },
          ],
        },
        visibilityFieldOptions: {
          show: (!ui.isEmbedded || showShare) && account.isBrokerageAccount,
          disabled: !canChangeVisibility,
          validValues: ['PERSONAL', 'TEAMS', 'BROKERAGE'],
          radioOptions: [
            {
              tooltip: {
                title:
                  template && !template.owned
                    ? 'Only creator of template can change it to "Just me".'
                    : undefined,
                placement: 'left',
              },
              value: 'PERSONAL',
              label: 'Just me',
              disabled: template && !template.owned,
            },
            {
              value: 'TEAMS',
              showTeamIdsFieldWhenSelected: true,
              label: `Specific Offices & Groups`,
              disabled: !selectableTeamIds.length,
            },
            ...(showShareWithEveryone
              ? [
                  {
                    value: 'BROKERAGE',
                    label: 'Everyone',
                    disabled: !account.isBrokerageAdmin,
                  },
                ]
              : []),
          ],
        },
        teamIdsFieldOptions: {
          disabled: !selectableTeamIds.length,
          requiredValues: allTeamIds,
          optionGroups: [
            ['Offices', visibleTeams.offices ?? []],
            ['Groups', visibleTeams.groups ?? []],
          ]
            .filter(([_, choices]) => choices.length)
            .map(([groupTitle, choices]) => ({
              title: groupTitle,
              options: (choices as any[]).map(({ id: teamId, title }) => {
                return {
                  value: teamId,
                  label: title,
                  disabled: !selectableTeamIds.includes(teamId),
                };
              }),
            })),
        },
        initialValues,
        defaultOkButtonDisabled: false,
        onOk: async (result: { data?: Record<string, any> }) => {
          const templateId = template?.id;
          const values = result?.data ?? {};
          const typeValue = values.type ?? initialValues.type;
          const sideValue = typeValue?.replace('_LEASE', '');
          const isLeaseValue = typeValue?.includes('_LEASE');
          const savedPropertyInfoId =
            values.savedPropertyInfoId ?? initialValues.savedPropertyInfoId;
          const visibility =
            (account?.isBrokerageAccount ? values.visibility : 'PERSONAL') ||
            'PERSONAL';

          const data = {
            ...values,
            title: values.title ?? initialValues.title,
            type: undefined,
            side: !template && !clone ? sideValue : undefined,
            isLease: isLeaseValue,
            visibility,
            teamIds: visibility === 'TEAMS' ? values.teamIds : [],
            clone: !template && clone ? clone.id : undefined,
            cloneFromTransaction: cloneFromTransaction || undefined,
            savedPropertyInfoId,
          };

          try {
            const save = templateId
              ? partial(transactionTemplates.edit, templateId)
              : transactionTemplates.create;
            const template = await save(data as any);
            const verb = templateId ? 'Edited' : 'Created';
            let description = `${
              template.meta.title
            } template was successfully ${verb.toLowerCase()}.`;

            if (!templateId && template.hasDeactivatedForms) {
              description = `${template.deactivatedForms} form${
                template.deactivatedForms > 1 ? 's were' : ' was'
              }
             not copied into the transaction because ${
               template.deactivatedForms > 1 ? 'they have' : 'it has'
             } been deactivated`;
            }

            ui.toast({
              message: `${verb} ${template.meta.title}.`,
              description,
              type: 'success',
            });

            const targetRouteName =
              templateDetailRouteName || 'transactionTemplates.edit';
            if (
              navigateOnOk &&
              (router?.hotRoute?.name !== targetRouteName ||
                router?.hotRoute?.params?.transactionId !== template.id)
            ) {
              await router.navigatePromise(targetRouteName, {
                transactionId: template.id,
                back:
                  router?.hotRoute?.params?.back ||
                  JSON.stringify(pick(router.route, ['name', 'params'])),
              });
            }
          } catch (err: any) {
            ui.wentWrong(err);
          }
        },
      }
    );
  } catch (err) {
    if (!err) {
      return; // cancel
    }
    console.error('TemplateMetaModal error', err);
  }
};
